<template>
    <div class="card">
        <TabView class="px-1" @tab-change="changeTitle($event)" :activeIndex="activeIndex">
            <TabPanel header="Customer List">
            </TabPanel>
            <TabPanel header="Request Upgrade">
            </TabPanel>
        </TabView>
        <router-view />
    </div>
</template>

<script>

export default {
    data() {
        return {
            activeIndex: 0,
        }
    },
    watch: {
        '$route': 'fetchLink',
    },
    mounted() {
        this.fetchLink()
    },
    methods: {
        fetchLink() {
            let pathName = this.$route.name
            if (pathName === 'customer-list-upgrade') {
                this.activeIndex = 1
            } else {
                this.activeIndex = 0
            }
        },
        changeTitle(event) {
            if (event.index === 0) {
                this.$router.push({name: 'customer-list'})
            } else if (event.index === 1) {
                this.$router.push({name: 'customer-list-upgrade'})
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>